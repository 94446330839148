export default {
  partner: {
    login: {
      meta: {
        title: 'Kreditanfrage als Partner/-in einreichen',
        description:
          'Sie sind als Partner/-in bei uns registriert und möchten einen Firmenkredit für Ihre Kundin oder Ihren Kunden anfragen? Das geht ganz schnell und einfach.',
      },
      header: {
        title: 'Jetzt online Ihre Partneranfrage stellen',
      },
      form: {
        title: 'Partner/-in',
        para1: 'Bitte machen Sie hier Angaben zu Ihrer eigenen Person.',
        fields: {
          serverErrorMessage:
            'Bitte überprüfen Sie Ihre Angaben oder kontaktieren Sie unser Service-Team.',
        },
      },
    },
    application: {
      header: {
        title: 'Hallo {fullName}',
        description:
          'Stellen Sie hier die Kreditanfrage für Ihre Kundin oder Ihren Kunden.',
      },
      form: {
        notAcceptedError:
          'Leider können wir Ihre Kreditanfrage nicht annehmen. Wir konnten Sie keinem unserer aktiven Partner zuordnen. Bitte kontaktieren Sie Ihren Key Account Manager oder melden Sie sich unter {linkTo}',
        creditSection: {
          title: '1. Kreditwunsch',
          para1:
            'Einfach die gewünschte Kreditsumme und die Laufzeit eingeben. Mit der Einschätzung der Bonität Ihrer Kundin oder Ihres Kunden bekommen Sie dann schon einen ersten Eindruck des möglichen Zinssatzes und der Höhe der monatlichen Rate.',
          fields: {
            purpose: {
              label: 'Verwendungszweck',
              placeHolder: '– Bitte auswählen –',
            },
          },
        },
        companySection: {
          title: '2. Firmeninformationen',
          para1:
            'Wir brauchen nur ein paar Informationen, um das Unternehmen einschätzen zu können.',
          para2:
            'Wenn Sie den Firmennamen eingeben und aus der Liste auswählen, wird das Anfrageformular <em>automatisch mit den Firmendaten vervollständigt</em>. So sparen Sie noch mehr Zeit.',
          fields: {
            purpose: {
              label: 'Verwendungszweck',
              placeHolder: '– Bitte auswählen –',
            },
          },
        },
        representativeSection: {
          title: '3. Vertretungs{shyCharacter}berechtigte/-r',
          para1:
            'Als Vertretungsberechtigte werden derzeit <em>nur Geschäftsführer/-innen oder Vorstandsmitglieder</em> akzeptiert. Eine Anfrage durch Prokurist/-innen und sonstige Bevollmächtigte ist leider nicht möglich.',
          fields: {
            emailInfo:
              'Keine Sorge, wir nutzen Ihre E-Mail-Adresse, um Ihnen wichtige Unterlagen zu Ihrem Produkt zu senden und Sie über unsere Services zu informieren. Dem können Sie jederzeit per E-Mail an <a href = "mailto: info@ing-diba.de">info@ing-diba.de</a> widersprechen.',
          },
        },
        termsSection: {
          title: '4. Allgemeine Geschäftsbedingungen',
          para1:
            'Durch die von Ihnen angegebenen Daten kann die ING der Antragstellerin oder dem Antragsteller - falls die Kreditprüfung positiv ausfällt - ein unverbindliches Kreditangebot unterbreiten. Bitte beachten Sie, dass dieser Kreditantrag kostenlos und unverbindlich ist. Die Antragstellerin oder der Antragsteller ist nicht zu einer Annahme einer Finanzierung durch die ING verpflichtet. Die ING hat zudem keine Pflicht, dem/der Antragsteller/-in eine Finanzierung anzubieten.',
          para2: {
            text: 'Mit dem Klicken auf {boldContent} bestätige ich, mit umfassender Bevollmächtigung des von mir vertretenen Unternehmens zu handeln und den Kreditantrag einzureichen. Ich bestätige, dass ich sowie alle (weiteren) im Handelsregister eingetragenen Geschäftsführer/-innen, Gesellschafter/-innen und wirtschaftlich Berechtigte im Sinne des § 3 Abs. 1 GwG – Geldwäschegesetz die {linkText} zur Kenntnis genommen haben.',
            linkText: 'Datenschutzerklärung',
            boldContent: '„Firmenkredit Anfragen“',
          },
          para3: {
            text: 'Abschließend bestätige ich, dass dem mich bevollmächtigenden Unternehmen sowie den von der Datenverarbeitung betroffenen Personen bekannt ist, dass die ING unternehmens- und personenbezogene Daten an die {linkText1} und die {linkText2} übermittelt bzw. von den genannten Stellen abfragt. Dies ist zur Beurteilung der Bonität erforderlich.',
            linkText1: 'Creditreform',
            linkText2: 'SCHUFA',
          },
        },
      },
    },
    success: {
      header: {
        title: 'Vielen Dank für Ihre Anfrage',
        description:
          'Wir bestätigen Ihre Finanzierungsanfrage noch einmal in einer separaten E-Mail und informieren Sie über die nächsten Schritte.',
      },
      details: {
        title: 'Ihre Daten auf einen Blick:',
      },
      nextSteps: {
        title: 'Und so geht’s weiter',
        step1: {
          title: 'Schritt 1',
          text: 'Damit wir Ihnen schnell einen Finanzierungsvorschlag für Ihren Kunden / Ihre Kundin machen können, schicken Sie uns bitte folgende Unterlagen verschlüsselt an {linkText}:',
          linkText: 'partneranfrage@ing.de',
          list: {
            item1: {
              text: '{boldContent} inkl. aller Kontennachweise. Wenn zu Jahresbeginn für das letzte Geschäftsjahr keine finalen Dokumente vorliegen, bitte stattdessen Ihre Betriebswirtschaftliche Auswertung mit Summen- und Saldenliste',
              boldContent: 'Jahresabschlüsse der letzten zwei Geschäftsjahre',
            },
            item2: {
              text: '{boldContent} mit Summen- und Saldenliste, nicht älter als drei Monate',
              boldContent:
                'Betriebswirtschaftliche Auswertung des aktuellen Geschäftsjahres',
            },
            item3: {
              text: '{boldContent} der letzten 90 Tage',
              boldContent: 'Kontoauszüge',
            },
          },
        },
        step2: {
          title: 'Schritt 2',
          text: 'Sobald die Unterlagen vollständig sind, senden wir Ihnen {0} einen {1} und unverbindlichen {2} für Ihren Kunden / Ihre Kundin.',
          boldContents: [
            'in wenigen Tagen',
            'kostenfreien',
            'Finanzierungsvorschlag',
          ],
        },
        step3: {
          title: 'Schritt 3',
          text: 'Wenn Ihr Kunde / Ihre Kundin den {boldContent}, schicken wir ihm / ihr eine E-Mail mit allen Informationen zur Identifizierung.',
          boldContent: 'Finanzierungsvorschlag annimmt',
        },
        step4: {
          title: 'Schritt 4',
          text: 'Nachdem die {boldContent} erfolgt ist, erhält Ihr Kunde / Ihre Kundin die Vertragsunterlagen zur Unterzeichnung.',
          boldContent: 'Identifizierung',
        },
        step5: {
          title: 'Schritt 5',
          text: 'Das Geld wird dann {boldContent} Ihres Kunden / Ihrer Kundin überwiesen.',
          boldContent: 'direkt auf das Konto',
        },
        dataSection: [
          {
            title: 'Partner/-in',
            dataList: {
              firstName: 'Vorname:',
              lastName: 'Nachname:',
              email: 'E-Mail-Adresse:',
            },
          },
          {
            title: 'Kreditdetails',
            dataList: {
              amount: 'Kreditbetrag:',
              years: 'Laufzeit:',
              duration: '{n} Jahr | {n} Jahre',
              purpose: 'Verwendungszweck:',
            },
          },
          {
            title: 'Firmeninformationen',
            dataList: {
              companyName: 'Firmenname:',
              address: 'Adresse:',
              foundedDate: 'Gründungsdatum:',
              revenue: 'Umsatz des letzten Jahres:',
              legalForm: 'Rechtsform:',
              billingMethod: 'Bilanzierungsmethode:',
            },
          },
          {
            title: 'Vertretungsberechtigte/-r',
            dataList: {
              gender: 'Anrede:',
              firstName: 'Vorname:',
              lastName: 'Nachname:',
              address: 'Adresse:',
              dateOfBirth: 'Geburtsdatum:',
              nationality: 'Staatsangehörigkeit:',
              mobile: 'Mobilfunknummer:',
              email: 'E-Mail-Adresse:',
            },
          },
        ],
      },
    },
  },
  becomePartner: {
    meta: {
      title: 'Als Partner/-in registrieren',
      description:
        'Vermitteln Sie als Partner/-in unseren Firmenkredit bis 750.000 an Ihre Geschäftskundinnen und -kunden. Registrieren Sie sich hier schnell und einfach.',
    },
    header: { title: 'Partner/-in werden' },
    form: {
      person: {
        header: 'Person',
      },
      contact: {
        header: 'Kontakt',
      },
      firm: {
        header: 'Firmenanschrift',
      },
      info: {
        header: 'Allgemeine Geschäftsbedingungen',
        privacyPolicy: {
          label: `Ich bestätige, dass ich sowie alle (weiteren) im Handelsregister eingetragenen Geschäftsführer/-innen und Gesellschafter/-innen die {linkText} zur Kenntnis genommen haben.`,
          linkText: 'Datenschutzerklärung',
        },
      },
    },
    success: {
      header: {
        title: 'Sie haben Ihre Daten erfolgreich übermittelt.',
        description:
          'Wir melden uns mit Ihren Vertragsunterlagen und weiteren Informationen.',
      },
    },
  },
};
